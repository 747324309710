import 'leaflet/dist/leaflet.css';
require('leaflet/dist/leaflet.js');

import 'beautifymarker/leaflet-beautify-marker-icon.css';
require('beautifymarker/leaflet-beautify-marker-icon.js');

require('leaflet.markercluster/dist/leaflet.markercluster.js');
import 'leaflet.markercluster/dist/MarkerCluster.Default.css';


require('leaflet-draw/dist/leaflet.draw.js');
import 'leaflet-draw/dist/leaflet.draw.css';

require('../leaflet/leaflet_point_in_polygon');

// require('leaflet-switch-basemap/src/L.switchBasemap.js');
// import 'leaflet-switch-basemap/src/L.switchBasemap.css';