import _html_helper from './_html_helper'

var OffcanvasMenu = {
    element: ".card.offcanvas",
    translateX: '305',
    generate: function(options={}){
      let html = '<div class="card offcanvas offcanvas-wmd offcanvas-hmd '+(options.position || 'left')+' box-shadow-sm card-hover" id="'+options.id+'">'+
                '<div class="offcanvas-body">';
      html+= (options.title) ? _html_helper.generateTitle(options.title, options.subtitle) : ""
      html += '<div class="card-body"><div class="content"></div></div>'+
              '</div>'+
            '</div>'
      $(document.body).append(
        html
      )
           
    },
    appendToBody:function(html){
      $(this.element).find('.content').append(html)
    },
    show: function(){
      if($(this.element).length > 0){
        $(this.element).addClass('show')
      } else {
        console.warn('Tentativa de mostrar um offcanvas não gerado. Utilize o metodo "generate"')
      }
      
    },
    hide: function(){
      $(this.element).removeClass('show')
      $(this.element).trigger('hidden')
    },
    /**
     * Esconde todos os Offcanvas
     */
    hideAll: function(){
      $('.card.offcanvas').removeClass('show')
    },
    /**
     * Esconde todos os outros offcanvas cards que estão abertos além diferentes do elemento atualmente setado
     * A idéia é em casos de múltiplos offcanvas na mesma página, evitar offcanvas sobrepostos
     * A sequência ideial seria: Set offcanvas, hideAnyButElement, Toggle
     */
    hideAnyButElement(){
      let elem = $(this.element)[0]
      $('.card.offcanvas').each((index, card)=>{
        if(elem != card){
          $(card).removeClass('show')
        }
      })
    },
    showContent(container){
      anime({
        targets: this.element + " " + container,
        opacity: 1,
        duration: 300,
        easing: 'easeInOutQuad',
        begin: function() {
          document.querySelector(OffcanvasMenu.element + " " + container).style.display = 'block';
        },  
      });
    },
    hideContent(container){
      return anime({
        targets: this.element + " " + container,
        opacity: 0,
        duration: 300,
        easing: 'easeInOutQuad',
        complete: function(e) {
          document.querySelector(OffcanvasMenu.element + " " + container).style.display = 'none';
        },  
      });
    },
    toggle: function(){
      if($(this.element).hasClass("show")){
        this.hide();
      } else {
        this.show();
      }
    },
    elementIsHidden: function(){
      return !$(this.element).hasClass("show")
    },
    setElement: function(element){ //identificador do elemento
      this.element = element
    }
}
window.OffcanvasMenu = OffcanvasMenu