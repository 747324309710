function BaseConstructor(){
    this.eventListeners = {};
}
BaseConstructor.prototype.on = function(eventName, callback) {
    if (!this.eventListeners[eventName]) {
      this.eventListeners[eventName] = [];
    }
    this.eventListeners[eventName].push(callback);
  };

BaseConstructor.prototype.trigger = function (eventName, ...args) {
    const listeners = this.eventListeners[eventName];
    if (listeners) {
      listeners.forEach((callback) => {
        callback.apply(this, args);
      });
    }
};
BaseConstructor.prototype.createElement = function(type, classList, content){
  let el = document.createElement(type)
  if(classList){
      $(el).addClass(classList)
  }
  if(content){
      $(el).html(content)
  }
  
  return el 
}

global.BaseConstructor = BaseConstructor