import '../toasty/toasty.min.css';
require('../toasty/toasty.js');
//definindo 'toast' como padrao para invocar lib no navegador e configuração inicial
global.toast = new Toasty();

toast.configure(
    {
        classname: "toast",
        transition: "pinItDown",
        insertBefore: true,
        duration: 3000,
        enableSounds: false,
        autoClose: true,
        progressBar: true,
    }
);