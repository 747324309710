export default {
    generateTitle(title,subtitle){
        return '<div class="title-container">'+
           '<div class="title">'+title+'</div>'+
            this.generateCloseButton() +
            ((subtitle) ? '<div class="subtitle">'+subtitle+'</div>' : "")+
        '</div>'
    },
    generateCloseButton(){
        return '<button type="button" class="close text-muted" onclick="OffcanvasMenu.hide()">'+
        '<span aria-hidden="true">×</span>'+
    '</button>'
    }
}